import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import '../i18n/i18n';
import { getLocaleKeys } from '../i18n/locales';
import { baseURL } from '../consts/const';

export const HomePageHead = (props: { fromTimeZone: string, toTimeZone: string, fromTime?: string, language: string }) => {
    const { t, i18n } = useTranslation();
    const { fromTimeZone, toTimeZone, fromTime } = props;
    const fromTimeZoneDisplayName = t(`tz.${fromTimeZone}.displayName`);
    const toTimeZoneDisplayName = t(`tz.${toTimeZone}.displayName`);


    const headFT = () => {
        return (
            <Helmet htmlAttributes={{ lang: props.language }}>
            <meta charSet="utf-8" />
            <title>{t('headHomepage.titleWithFT', {
                fromTime: props.fromTime,
                fromTimeZone: fromTimeZone,
                toTimeZone: toTimeZone,
            })}</title>

            <meta name="description" content={t('headHomepage.descriptionFT', {
                fromTime: props.fromTime,
                fromTimeZone: fromTimeZone,
                toTimeZone: toTimeZone,
                fromTimeZoneDisplayName: fromTimeZoneDisplayName,
                toTimeZoneDisplayName: toTimeZoneDisplayName,
            })} />

            <meta name="keywords" content={t('headHomepage.keywordsFT', {
                fromTime: props.fromTime,
                fromTimeZone: fromTimeZone,
                toTimeZone: toTimeZone,
                fromTimeZoneDisplayName: fromTimeZoneDisplayName,
                toTimeZoneDisplayName: toTimeZoneDisplayName,
            })} />


            {
                getLocaleKeys().map(lang => {
                    const url = `${baseURL}/${lang}/timezone-converter/${fromTime}-${fromTimeZone.toLowerCase()}-to-${toTimeZone.toLowerCase()}`;
                    return <link rel="alternate" hrefLang={lang}
                        href={url}
                        key={url}
                    />;
                })
            }

            <link rel="alternate" hrefLang="x-default" href={`${baseURL}/en/timezone-converter/${fromTime}-${fromTimeZone.toLowerCase()}-to-${toTimeZone.toLowerCase()}`} key='xdefault' />
            <link rel="canonical" href={`${baseURL}/en/timezone-converter/${fromTime}-${fromTimeZone.toLowerCase()}-to-${toTimeZone.toLowerCase()}`} key='canonical' />
        </Helmet>
        );
    };

    if (props.fromTime) {
        return headFT();
    }

    const head = () => {
        return (
            <Helmet htmlAttributes={{ lang: props.language }}>
            <meta charSet="utf-8" />
            <title>{t('headHomepage.title', { fromTimeZone: fromTimeZone, toTimeZone: toTimeZone })}</title>
            <meta name="description" content={t('headHomepage.description', {
                fromTimeZone: fromTimeZone,
                toTimeZone: toTimeZone,
                fromTimeZoneDisplayName: fromTimeZoneDisplayName,
                toTimeZoneDisplayName: toTimeZoneDisplayName,
            })} />
            <meta name="keywords" content={t('headHomepage.keywords', {
                fromTimeZone: fromTimeZone,
                toTimeZone: toTimeZone,
                fromTimeZoneDisplayName: fromTimeZoneDisplayName,
                toTimeZoneDisplayName: toTimeZoneDisplayName,
            })} />

            {
                getLocaleKeys().map(lang => {
                    const url = `${baseURL}/${lang}/timezone-converter/${fromTimeZone.toLowerCase()}-to-${toTimeZone.toLowerCase()}`;
                    return <link rel="alternate" hrefLang={lang}
                        href={url}
                        key={url}
                    />;
                })
            }
            <link rel="alternate" hrefLang="x-default" href={`${baseURL}/en/timezone-converter/${fromTimeZone.toLowerCase()}-to-${toTimeZone.toLowerCase()}`} key='xdefault' />
            <link rel="canonical" href={`${baseURL}/en/timezone-converter/${fromTimeZone.toLowerCase()}-to-${toTimeZone.toLowerCase()}`} key='canonical' />
        </Helmet>
        );
    }
    
    return head();


}